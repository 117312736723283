.MuiTimelineItem-alignAlternate:nth-child(even) .MuiTimelineItem-content {
    text-align: left !important;
}

#customers {
    border-collapse: collapse;
    width: 100%;
}

#customers td,
#customers th {
    border: none;
    padding: 8px;
}

#customers tr:nth-child(even) {
    background-color: #f2f2f2;
}

#customers th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left;
}

#product {
    border-collapse: collapse;
    width: 100%;
}

#product td,
#product th {
    border: none;
    padding: 8px;
}

#product tr:nth-child(even) {
    background-color: #f2f2f2;
}
#product thead tr th {
    background-color: #0f4c75;
    color: #ffffff;
}

#product th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left;
}

.custom-timeline:before {
    flex: 0 !important;
}

.model-position .MuiDialog-paperScrollPaper {
    max-height: calc(100%) !important;
    margin: 0 !important;
    position: absolute;
    top: 0;
    right: 0;
}

.model-left-position .MuiDialog-paperScrollPaper {
    max-height: calc(100%) !important;
    margin: 0 !important;
    position: absolute;
    top: 0;
    left: 0;
}

::-webkit-scrollbar {
    width: 4px;
}

::-webkit-scrollbar-track {
    visibility: hidden;
}

::-webkit-scrollbar-thumb {
    background: #4d4c7d !important;
    border-radius: none;
}

::-webkit-scrollbar-thumb:hover {
    background: #363062;
}

.MuiStepLabel-label.MuiStepLabel-completed {
    color: green !important;
}

.MuiStepIcon-root.MuiStepIcon-completed {
    color: rgb(76, 175, 80) !important;
}

.MuiStepLabel-label.MuiStepLabel-active,
.MuiStepIcon-root.MuiStepIcon-active {
    color: #3282b8 !important;
}

.step-error .MuiStepIcon-root {
    color: #c70039 !important;
}
.step-error .MuiStepLabel-label {
    color: red !important;
    font-weight: 400;
}

.MuiTimelineItem-missingOppositeContent:before {
    padding: 0 !important;
}

.float-right {
    float: right !important;
}

@media (max-width:1279.95px) {
    .makeStyles-icon-26 {
      top: 0 !important
    }
  }